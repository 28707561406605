<template>
  <div>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-restaurant" />
        <h5 class="d-inline ml-2">{{ this.$route.meta.label }}</h5>
        <div class="card-header-actions">
          <ul class="pagination justify-content-center m-0">
            <li v-if="step > 1" class="page-item">
              <CButton class="d-none d-sm-block" color="link" @click="previous()" :disabled="!isComponentRender || submitted">
                ‹ Previous
              </CButton>
            </li>
            <li class="page-item">
              <CButton :color="step !== steps ? 'info' : 'primary'" @click="next()" :disabled="!isComponentRender || submitted">
                {{ step !== steps ? 'Next ›' : 'Submit' }}
              </CButton>
            </li>
          </ul>
        </div>
      </CCardHeader>

      <CCardBody>
        <div class="restaurant-wizard">
          <v-stepper
            ref="stepper"
            :steps="steps"
            v-model="step"
            :persist="true"
          >
            <template v-for="(title, index) in stepTitles" :slot="`step-${index}`">
              {{ title }}
            </template>
          </v-stepper>
        </div>

        <div class="pt-3">
          <div class="my-4" >
            <h5 class="pb-1" style="border-bottom: 4px solid #321fdb;">{{ stepTitles[step] }}</h5>
          </div>

          <div class="mx-3">
            <transition name="fade">
              <RestaurantWizardStep1 v-if="step === 1" ref="step_1" @previous="previousStep" @next="nextStep" @reset="resetWizard" @loaded="stepLoaded" />
              <RestaurantWizardStep2 v-if="step === 2" ref="step_2" @previous="previousStep" @next="nextStep" @reset="resetWizard" @loaded="stepLoaded" />
              <RestaurantWizardStep3 v-if="step === 3" ref="step_3" @previous="previousStep" @next="nextStep" @reset="resetWizard" @loaded="stepLoaded" />
              <RestaurantWizardStep4 v-if="step === 4" ref="step_4" @previous="previousStep" @next="nextStep" @reset="resetWizard" @loaded="stepLoaded" />
              <RestaurantWizardStep5 v-if="step === 5" ref="step_5" @previous="previousStep" @next="nextStep" @reset="resetWizard" @loaded="stepLoaded" />
              <RestaurantWizardStep6 v-if="step === 6" ref="step_6" @previous="previousStep" @next="nextStep" @reset="resetWizard" @loaded="stepLoaded" />
              <RestaurantWizardStep7 v-if="step === 7" ref="step_7" @previous="previousStep" @next="submit" @reset="resetWizard" @loaded="stepLoaded" />
            </transition>

            <CElementCover :opacity="0.4" v-show="!isComponentRender" />
          </div>
        </div>
      </CCardBody>

      <CCardFooter class="sticky-bottom">
        <div class="d-flex flex-wrap align-items-center">
          <!-- Reset Form -->
          <CButton variant="outline" color="danger"  @click="confirmReset" :disabled="!isComponentRender || submitted">
            Reset Form
          </CButton>

          <div class="ml-auto">
            <CButton class="d-sm-none" color="link" @click="previous()" :disabled="!isComponentRender || submitted">
              ‹ Previous
            </CButton>

            <!-- Submit Form -->
            <CButton :color="step !== steps ? 'info' : 'primary'" @click="next()" :disabled="!isComponentRender || submitted">
              {{ step !== steps ? 'Next ›' : 'Submit' }}
            </CButton>
          </div>
        </div>
      </CCardFooter>

      <mc-spinner :opacity="0.8" v-show="submitted" :text="spinnerText" />
    </CCard>

  </div>

</template>

<script>
import { VStepper } from "vue-stepper-component";

export default {
  name: "RestaurantWizardContainer",
  components: {
    VStepper,
    RestaurantWizardStep1: () => import('./Step1'),
    RestaurantWizardStep2: () => import('./Step2'),
    RestaurantWizardStep3: () => import('./Step3'),
    RestaurantWizardStep4: () => import('./Step4'),
    RestaurantWizardStep5: () => import('./Step5'),
    RestaurantWizardStep6: () => import('./Step6'),
    RestaurantWizardStep7: () => import('./Step7'),
  },
  data: () => ({
    steps: 7,
    step: undefined,
    stepTitles: [
      "",
      "General Information",
      "Types",
      "Opening Time",
      "Delivery Time",
      "ZIP Codes",
      "Company Info",
      "Sign & Finish",
    ],
    isComponentRender: false,
    submitted: false,
    spinnerText: null
  }),
  watch: {
    step() {
      if (this.routeStep != this.step) {
        this.$router.push({
          name: this.$route.name,
          query: { step: this.step },
        });
      }
    },
  },
  computed: {
    routeStep() { return parseInt(this.$route.query.step) || 1; },
    routeId() { return this.$route.params.id; }
  },
  mounted() {
    this.step = this.routeStep;
  },
  methods: {
    stepLoaded() {
      this.isComponentRender = true;
    },
    next() {
      this.$refs[`step_${this.step}`]?.next();
    },
    previous() {
      this.$refs[`step_${this.step}`]?.previous();
    },
    nextStep(res) {
      res && this.$refs.stepper.next();
    },
    previousStep(res) {
      res && this.$refs.stepper.previous();
    },

    submit(preparing, ready = false) {
      window.scrollTo(0, 0);
      this.submitted = preparing || ready;

      if (preparing && !ready) {
        this.spinnerText = 'The contract is uploading...';
      } else if (ready) {
        this.spinnerText = 'The restaurant is being saved...';

        this.store()
          .then((response) => {
            this.$toast.success('The restaurant has been successfully added.');
            this.submitted = false;

            this.resetWizard();

            setTimeout(async () => {
              await this.$router.push({ name: 'Restaurants' });
            }, 100)
          })
          .catch(({ response }) => {
            this.$toast.error(response.data?.message ?? response);
            this.submitted = false;
          })
      }
    },

    async store() {
      const form = JSON.parse(localStorage.getItem("pending-restaurant"));
      await this.$axios.post(this.$backend.RESTAURANT.STORE_WIZARD, form);
    },

    resetWizard() {
      this.$refs.stepper.reset();
      sessionStorage.clear();

      Object.keys(localStorage).forEach(key => key.startsWith('v-stepper-') && localStorage.removeItem(key));
      localStorage.removeItem("pending-restaurant");
      localStorage.removeItem("pending-restaurant-contract");
      localStorage.removeItem("contract");
    },

    confirmReset() {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ All changes will be lost. Are you sure you want to continue?`,
        button: { no: "No", yes: "Yes" },
        callback: async (confirm) => {
          if (confirm) {
            this.resetWizard();
            this.$refs.step_1?.resetForm();
          }
        }
      });
    }
  },
};
</script>

<style>
/* Restaurant Creation Wizard */
.restaurant-wizard + div .form-control {
  /* zoom: 120%; */
  font-size: 1.125rem;
  color: #9b0a0a;
  font-weight: 500;
}

/* prevent click events on all steppers */
.restaurant-wizard div.v-stepper > div.v-stepper-root > div.v-step {
  pointer-events: none;
}


.restaurant-wizard .index {
  color: #3c4b64;
}
.restaurant-wizard .title {
  color: #3c4b64;
  font-weight: 600;
}
.restaurant-wizard .v-step .label .index {
  border: 6px solid #f4f4f4;
}
.restaurant-wizard .v-step.is-active .label .index {
  color: #fff;
  background-color: #9b0a0a;
}
.restaurant-wizard .v-step.is-visited {
  opacity: 0.75;
}
.restaurant-wizard .v-step.is-visited .label .index {
  color: #000;
  border: 6px solid #9b0a0a;
}

@media (max-width: 1199.98px) {
  .restaurant-wizard .v-step .title {
    display: none;
  }
}

@media (max-width: 575.98px) {
  /* .restaurant-wizard .v-step .title {
    white-space: nowrap;
    margin-left: 10px;
  }
  .restaurant-wizard .v-stepper-root {
    flex-direction: column;
  }
  .restaurant-wizard .v-step .title {
    display: block;
  } */

  .restaurant-wizard .v-step {
    margin-right: 0rem;
  }
  .restaurant-wizard .v-step .divider {
    display: block;
  }
  .restaurant-wizard .v-step .index {
    width: 2.25rem;
    height: 2.25rem;
    font-size: 1rem;
    border-width: 3px !important;
    margin-right: 0rem;
  }
}
</style>
